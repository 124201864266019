import * as React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Typography } from '@mui/material';
import { IGlobalStoreState } from '../../../store';
import { Button, Message, MessageButtonsWrapper } from '../../../components/UI';
import { ResultInfoButtonConfigType, ResultInfoType } from '../../../store/resultInformarion/types';
import { AppTheme } from '../../../settings/appTheme';
import { KeySuccess } from '../../../store/enums';
import { clearInfo } from '../../../store/resultInformarion/actions';

export const InformationSuccess = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const config = useSelector<IGlobalStoreState, ResultInfoType>((state) => state.resultInfo);

  useEffect(() => {
    return () => {
      dispatch(clearInfo());
    };
  }, [dispatch]);

  if (!config.key) {
    return null;
  }
  const { key, title, message, buttons } = config;

  const background = key?.includes('email') ? AppTheme.img.backgrounds.bg2 : AppTheme.img.backgrounds.bg1;

  const isEmailSentKey = key === KeySuccess.EmailSent;
  const icon = isEmailSentKey ? AppTheme.img.icons.mail : AppTheme.img.icons.rock;

  const handleButtonClick = (e: any, button: ResultInfoButtonConfigType) => {
    if (button.callback) {
      button.callback();
    }
  };

  return (
    <AnimatedDialog alertKey={key}>
      <picture>
        <source
          srcSet={`${background}.webp 1x, ${background}@2x.webp 2x, ${background}@3x.webp 3x`}
          type="image/webp"
        />
        <Background src={`${background}.jpg`} srcSet={`${background}@2x.jpg 2x, ${background}@3x.jpg 3x`} />
      </picture>
      <Message
        title={t(title)}
        description={t(message)}
        icon={icon}
        renderButtons={() =>
          buttons?.length && (
            <MessageButtonsWrapper>
              {buttons.map((btn, index) => (
                <Button key={index} onClick={(e) => handleButtonClick(e, btn)}>
                  {t(btn.title)}
                </Button>
              ))}
              {key === KeySuccess.PaymentSucceeded && (
                <>
                  <Button onClick={() => window.open('https://t.me/manager_bustourpraha')}>
                    НАПИСАТЬ МЕНЕДЖЕРУ БАС-ТУРА
                  </Button>
                  <Typography variant={'caption'} sx={{ marginTop: '10px' }}>
                    После оплаты билета на Бас-Тур обязательно напиши менеджеру, он добавит тебя в чат поездки.
                  </Typography>
                </>
              )}
            </MessageButtonsWrapper>
          )
        }
      />
    </AnimatedDialog>
  );
};

const Dialog = styled.dialog<{ maxWidth: string; theme: { colors: { text: string } } }>`
  position: absolute;
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  height: 100%;
  max-height: 100vh;
  z-index: 800;
  border: none;
  padding: 0;
  margin: 0;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  max-width: ${(props) => props.theme.maxWidth};
  color: ${(props) => props.theme.colors.text};
`;

const AnimatedDialog = (props: any) => <Dialog ref={props.hostRef} {...props} />;

const Background = styled.img`
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  max-width: ${(props) => props.theme.maxWidth};
  height: 100%;
  object-fit: cover;
  object-position: center;
`;
